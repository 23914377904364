<template>

    <div>
     <b-modal
       ref="modalNegocios"
       centered
       :size="size"
       hide-footer
       :title="title"
       @hidden="closeModalNegocio"
     >
       <div class="d-block text-center">
 
        
 
       <b-row v-if="showTypeNegocio">
 
         <b-col cols="12" sm="12" md="12" lg="12" xl="12">
 
 
        
           <div class="radio-tile-group">
                           <div class="input-container" >
                             <input id="fisico" class="radio-button" type="radio" @click="onChange($event)" value="fisico" name="radio" />
                             <div class="radio-tile">
                               <p class="radio-tile-label" style="margin-bottom: 5px">Negocio Físico</p>
                               <div class="icon walk-icon">
 
 
                                      <img style="width: 60px;" alt="Fisico" src="/img/negocio.svg">                 
                               </div>
                              
                             </div>
                           </div>
 
 
                           <div class="input-container" >
                             <input id="virtual" class="radio-button" type="radio" @click="onChange($event)" value="virtual" name="radio" />
                             <div class="radio-tile">
                               <p class="radio-tile-label" style="margin-bottom: 5px">Negocio virtual</p>
                               <div class="icon walk-icon">
 
 
                                      <img style="width: 60px;" alt="Online" src="/img/online.svg">                 
                               </div>
                             
                             </div>
                           </div>
 
                 
               
                  </div>
 
           </b-col>
 
 
 
 
       </b-row>
 
   
 
         <b-form autocomplete="off" @submit.prevent="onSubmitGeneral" v-else-if="showGeneral">
 
           <b-row>
             <b-col
                             cols="12"
                               sm="12"
                               md="12"
                               lg="12"
                               xl="12"
                               style="margin-bottom: 10px;"
                             >
 
                               <p   class="textLabel">
                                 Nombre del negocio (*):
                               </p>
 
     
 
                               <b-form-input
                                 v-model="name"
                                 autofocus
                                 placeholder="Ingrese el nombre del negocio"
                                 required
                                 type="text"
                               />
 
 
 
                             </b-col>
 
 
                             <b-col
                             cols="12"
                               sm="12"
                               md="12"
                               lg="12"
                               xl="12"
                             >
 
                               <p   class="textLabel">
                                 Alias del negocio <feather-icon
                                     icon="HelpCircleIcon"
                                     size="16"
                                       v-b-popover.hover.bottom.html="'El alias es una forma rápida de identificar tu negocio por parte de tus clientes'"
                                     title="Alias del negocio"
                                     style="margin-right: 5px;cursor:pointer;"
                                     />(*):
                               </p>
 
                               </b-col>
 
 
                               <b-col
                             cols="4"
                               sm="4"
                               md="4"
                               lg="4"
                               xl="4"
                               style="margin-bottom: 10px;display:flex"
                             >
                             <p style="margin:auto; text-align: left;">eqcoops.com/m/</p>
 
                             </b-col>
 
                             
                             <b-col
                             cols="8"
                               sm="8"
                               md="8"
                               lg="8"
                               xl="8"
                               style="margin-bottom: 10px;"
                             >
 
                             <b-form-input
                             v-model="alias"
                                     id="alias"
                                     maxlength="20"
                                     type="text"
                                     required
                                     @blur="checkAlias"
                                     @keyup="keyUpAlias" placeholder="Ingrese el alias" @paste.prevent
                               />
 
 
 
                             </b-col>
 
 
                   <b-col
                       sm="12"
                       md="12"
                       lg="12"
                       xl="12"
                       style="margin-bottom: 10px;"
                     >
 
 
                     <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                         Descripción del negocio (*):</p>
 
                         <b-form-textarea
                           v-model="description"
                           placeholder="Ingrese una descripción del negocio, lo podrá editar en otro momento"
                           rows="5"
                           required
                           no-resize
                           :state="description.length <= maxChar"
                           class="char-textarea"
                           :class="description.length >= maxChar ? 'text-danger' : ''"
                         />
                         <small
                           class="textarea-counter-value float-left"
                           :class="description.length >= maxChar ? 'bg-danger' : ''"
                         >
                           <span class="char-count">{{ description.length }}</span> / {{ maxChar }}
                         </small>
 
               
 
                     
 
                     </b-col>
 
                    <b-col sm="12" md="12" lg="12" xl="12" style="justify-content: space-between;display: flex" >
 
                     <b-button 
                                                             @click="atras1"
                                                             variant="outline-secondary"
                                                             style="margin-right: 15px;"
                                                           >
                                                           <feather-icon
 
                                                               icon="ArrowLeftCircleIcon"
                                                               size="13"
                                                               style= "margin-right:10px"
                                                               />
                                                             Atras 
 
                                                           </b-button>
 
                     <b-button   class="animacion_button"  type="submit" :disabled="isComprobando" id="btn_siguiente_general"   variant="primary" >  Siguiente   <feather-icon  icon="ArrowRightCircleIcon" size="15" style="margin-left: 5px;"/>       
                     
                     
 
                     </b-button>
 
 
 
                     </b-col>
           </b-row>
 
           </b-form>
 
 
           <b-row v-else-if="showInfoPermisoUbicacion">
             <b-col
                             cols="12"
                               sm="12"
                               md="12"
                               lg="12"
                               xl="12"
                               style="margin-bottom: 10px;"
                               align="center"
                             >
 
                             <p>Cuando de click en siguiente, se le solicitará que otorgue permiso para acceder a su ubicación actual, deberá dar click en "Permitir".</p>
 
                             <img src="/img/permiso2.png" style="width: 250px;"/>
 
 
                             </b-col>
 
                             <b-col
                             cols="12"
                               sm="12"
                               md="12"
                               lg="12"
                               xl="12"
                               style="margin-bottom: 10px;"
                               align="center"
                             >
 
                             <p style="text-align: left;"><strong>En caso de que no aparezca el mapa, puede intentar las siguientes alternativas:</strong></p>
 
                             <p style="text-align: left;margin:0px"><strong>1) </strong> Habilitar el permiso de ubicación manualmente.</p>
                             <p style="text-align: left;margin:0px"><strong>2) </strong> Intentarlo desde otro navegador o sistema operativo.</p>
                             <p style="text-align: left;margin:0px"><strong>3) </strong> Eliminar la cache del navegador e intentarlo nuevamente.</p>
                             </b-col>
 
             <b-col sm="12" md="12" lg="12" xl="12" style="justify-content: space-between;display: flex" >
 
                 <b-button 
                                                         @click="atras2"
                                                         variant="outline-secondary"
                                                         style="margin-right: 15px;"
                                                       >
                                                       <feather-icon
 
                                                           icon="ArrowLeftCircleIcon"
                                                           size="13"
                                                           style= "margin-right:10px"
                                                           />
                                                         Atras 
 
                                                       </b-button>
 
                 <b-button  @click="hidePermiso()"  class="animacion_button"    variant="primary" >  Siguiente   <feather-icon  icon="ArrowRightCircleIcon" size="15" style="margin-left: 5px;"/>       
 
 
 
                 </b-button>
 
 
 
                 </b-col>
 
 
                             </b-row>
 
                             <b-form autocomplete="off" @submit.prevent="onSubmitUbicacion" v-else-if="showUbicacion">
 
                             <b-row >
 
                               <b-col  cols="12" sm="12" md="12" lg="12" xl="12">
 
                                 <p   class="textLabel">
                                           Indique la ubicación de la empresa (*):
                                         </p>
 
                              <gmap-map
                                   @click="mark"
                                                 :center="center"
                                                 :zoom="16"
                                                 map-type-id="terrain"
                                                 style="width: 100%; height: 400px"
                                                 :options="{
                                                     zoomControl: true,
                                                     mapTypeControl: true,
                                                     scaleControl: true,
                                                     streetViewControl: true,
                                                     rotateControl: true,
                                                     fullscreenControl: true,
                                                     disableDefaultUi: true
                                                     }"
                                             >
                                                 
                                             <gmap-marker
                                                     :key="index"
                                                     v-for="(m, index) in markers"
                                                     :position="m.position"
                                                     :clickable="true"
                                                     :draggable="true"
                                                     @click="center=m.position"
                                                     @dragend="handleMarkerDrag"
                                                     
                                                 />
                                                 
                                     </gmap-map>
 
                                   </b-col>
 
                                     <b-col
                                       cols="12"
                                         sm="12"
                                         md="12"
                                         lg="12"
                                         xl="12"
                                         style="margin-bottom: 10px;margin-top:15px"
                                       >
 
                                         <p   class="textLabel">
                                           Dirección de la empresa (*):
                                         </p>
 
               
 
                                         <b-form-input
                                           v-model="address"
                                           placeholder="Ingrese la dirección del negocio"
                                           required
                                           type="text"
                                         />
 
 
 
                                       </b-col>
 
                                     <b-col sm="12" md="12" lg="12" xl="12" style="justify-content: space-between;display: flex" >
 
                                       <b-button 
                                                                               @click="atras3"
                                                                               variant="outline-secondary"
                                                                               style="margin-right: 15px;"
                                                                             >
                                                                             <feather-icon
 
                                                                                 icon="ArrowLeftCircleIcon"
                                                                                 size="13"
                                                                                 style= "margin-right:10px"
                                                                                 />
                                                                               Atras 
 
                                                                             </b-button>
 
                                       <b-button   class="animacion_button"  type="submit"   variant="primary" >  Siguiente   <feather-icon  icon="ArrowRightCircleIcon" size="15" style="margin-left: 5px;"/>       
 
 
 
                                       </b-button>
 
 
 
                                       </b-col>
 
                             </b-row>
 
                             </b-form>
 
 
                             <b-form autocomplete="off" @submit.prevent="onSubmitRegisterNegocio" v-else-if="showConfirmacion">
 
                               <b-row>
 
                                 <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:15px">
 
                                                                 
                                       <p style="font-weight: bold; font-size:14px; text-align: center; margin-top:0px;margin-bottom:10px">Datos del negocio</p>
 
                                  
                                       <div class="table-responsive " style="margin: 0;">
                                       <table class="table table-bordered" style="text-align: center;">
 
                                       <tbody>
 
                                       <tr>
 
                                           <td class="background_table">Nombre:</td>
                                           <td class="background_td_table"> <p style="font-size:12px; margin: 0px;text-align: right;">{{ name }}</p></td>
                                           </tr>
 
                                      
                                           <tr>
 
                                           <td class="background_table">Tipo:</td>
                                           <td class="background_td_table"> <p style="font-size:12px; margin: 0px;text-align: right;">Negocio {{ typeNegocio }}</p></td>
                                           </tr>
 
                                        
 
                                             <tr>
 
                                               <td class="background_table">Alias:</td>
                                               <td class="background_td_table"> <p style="font-size:12px; margin: 0px;text-align: right;">{{ alias }}</p></td>
                                               </tr>
 
                                               <tr>
 
                                           <td class="background_table">Descripción:</td>
                                           <td class="background_td_table scroll_div_table">
                                              <p style="font-size:12px; margin: 0px;text-align: right;"> {{ description }}</p>
                                             </td>
                                           </tr>
 
                                                                               
                                           <tr>
 
                                               <td class="background_table">Dirección:</td>
                                               <td class="background_td_table" v-if="typeNegocio === 'fisico'"> <p style="font-size:12px; margin: 0px;text-align: right;">{{ address }}</p></td>
                                               <td class="background_td_table" v-else> <p style="font-size:12px; margin: 0px;text-align: right;">No disponible para negocios virtuales</p></td>
                                               </tr>
 
                                               <tr>
 
                                               <td class="background_table">Ubicación:</td>
                                               <td class="background_td_table" v-if="typeNegocio === 'fisico'"> <p style="font-size:12px; margin: 0px;text-align: right;">{{ plusCode }}</p></td>
                                               <td class="background_td_table" v-else> <p style="font-size:12px; margin: 0px;text-align: right;">No disponible para negocios virtuales</p></td>
                                               </tr>
 
                                              
 
 
                                       </tbody>
                                       </table>
                                       </div>
 
 
                                       </b-col>
 
                                 <b-col
                                     sm="12"
                                     md="12"
                                     lg="12"
                                     xl="12"
                                   >
 
                                     <p
                                       class="textLabel"
                                       style="text-align: center;"
                                     >
                                       Ingrese su código PIN (*): <feather-icon
                                     icon="HelpCircleIcon"
                                     size="16"
                                       v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                     title="Código PIN de seguridad"
                                     style="margin-right: 5px;cursor:pointer;"
                                     /> </p>
 
                                     <PincodeInput
                                       v-model="pin"
                                       placeholder="-"
                                       :length="6"
                                       :autofocus="false"
                                       :secure="true"
                                       :readonly="isActive"
                                       required
                                     />
 
                                   </b-col>
 
                                 <b-col sm="12" md="12" lg="12" xl="12" style="justify-content: space-between;display: flex" >
 
                                     <b-button 
                                                                             @click="atras4"
                                                                             variant="outline-secondary"
                                                                             style="margin-right: 15px;"
                                                                           >
                                                                           <feather-icon
 
                                                                               icon="ArrowLeftCircleIcon"
                                                                               size="13"
                                                                               style= "margin-right:10px"
                                                                               />
                                                                             Atras 
 
                                                                           </b-button>
 
                                     <b-button :disabled="isActive"  id="btn_finalizar" ref="btn_finalizar" class="animacion_button"  type="submit"   variant="primary" >  
                                       Finalizar      
 
 
 
                                     </b-button>
 
 
 
                                     </b-col>
 
                               </b-row>
                             </b-form>
 
 
 
       </div>
 
 
       </b-modal>
    </div>
       
          
       
       </template>
       
       <script>
       import {
         BButton, BRow, VBPopover, BCol, BCard,  BFormInput, BForm, BFormTextarea
       } from 'bootstrap-vue'
       
       import PincodeInput from 'vue-pincode-input'
       
       export default {
         components: {
             BFormInput,
             BFormTextarea,
             BForm,
           BButton,
           BRow,
           BCol,
           BCard,
           PincodeInput
         },
         directives: {
           'b-popover': VBPopover,
         },
         props: ['userId','tokenAuth', 'idAdmin','userIdUser'],
         data() {
       
       
           return {
             maxChar: 150,
             center: {},
             markers: [],
             geocoder:null,
             title:"Paso 1 de 5 - Tipo de negocio",
             size:"sm",
             showTypeNegocio:true,
             showGeneral:false,
             typeNegocio:"",
             name:"",
             description:"",
             showInfoPermisoUbicacion:false,
              showUbicacion:false,
              address:"",
             plusCode:"",
             showConfirmacion:false,
             isActive:false,
             pin:"",
             isComprobando:false,
             alias:"",
             aliasRespaldo:"",
    
           }
         },
         computed: {
       
         },
         watch: {
       
         },
         mounted() {
 
        
 
   
 
         this.title="Paso 1 de 5 - Tipo de negocio"
           this.size="sm";
           this.showTypeNegocio=true;
           this.showGeneral=false;
           this.typeNegocio="";
 
         this.$refs.modalNegocios.show()
   
       
         },
         methods: {
 
             closeModalNegocio(){
 
      
          
                 this.$eventBus.$emit('reiniciarModalNegocioAdmin');
 
 
 
                 },
   
             instanceMap(){
       this.geocoder = new google.maps.Geocoder();
 
     },
     keyUpAlias() {
       this.alias = this.alias.toLowerCase()
 
       this.alias = this.alias.replace(/[^_-\w]+$/, '')
     },
     
     checkAlias() {

      let aliasSplit= this.alias.split("");

              
          const specialChar = "áéíóú~!@#$%^&´*_-+=`|(){}[]:;\"'<>,.?/";
          const hasSpecLet = aliasSplit.some((letter) => 
            specialChar.includes(letter)
          );


          if(hasSpecLet){

            this.alias="";

            this.$toast.error("Su alias es incorrecto, asegúrese de que no contenga ningún caracter especial como tildes, espacios en blanco etc", {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                          })

          }else{
            
                           if (this.alias == '') {
 
              
                             this.aliasRespaldo="";
 
                           } else if (this.alias != this.aliasRespaldo) {
 
                             
 
 
                           
                           document.getElementById('btn_siguiente_general').innerHTML = '<img style="margin-left:10px; width:15px " src="/img/loading2.svg" alt="Loading" />  Comprobando'
 
                           this.isComprobando=true;
 
                             const dataEncripted = this.$encryptBackoffice.encrypt(this.alias)
                         
                       this.$https.post('/locals/checkAlias/', { tokenAuth: this.tokenAuth, alias: dataEncripted }).then(response => {
 
                       
                         document.getElementById('btn_siguiente_general').innerHTML = 'Siguiente  <svg width="12" fill="#fff" height="12" style="margin-left: 15px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 240.823 240.823"><g><path id="Chevron_Right_1_" d="M183.189 111.816 74.892 3.555c-4.752-4.74-12.451-4.74-17.215.0-4.752 4.74-4.752 12.439.0 17.179l99.707 99.671-99.695 99.671c-4.752 4.74-4.752 12.439.0 17.191 4.752 4.74 12.463 4.74 17.215.0l108.297-108.261C187.881 124.315 187.881 116.495 183.189 111.816z"/><g/><g/><g/><g/><g/><g/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>'
                         this.isComprobando=false;
                               if (response.data.code == 200) {
 
 
                                 this.aliasRespaldo = this.alias
                               
                               } else {
                                 this.alias = ''
                                 this.aliasRespaldo="";
                           
                        
                                 this.$toast.error(response.data.message, {
                                   position: 'top-right',
                                   timeout: 3010,
                                   closeOnClick: true,
                                   pauseOnFocusLoss: false,
                                   pauseOnHover: true,
                                   draggable: true,
                                   draggablePercent: 0.4,
                                   showCloseButtonOnHover: false,
                                   hideProgressBar: true,
                                   closeButton: 'button',
                                   icon: true,
                                   rtl: false,
                                 })
                               }
                             }).catch(error => {
                               this.alias = ''
                               
                               this.aliasRespaldo = ''
                               this.$toast.error("Ocurrió un error inesperado " + error, {
                                 position: 'top-right',
                                 timeout: 3010,
                                 closeOnClick: true,
                                 pauseOnFocusLoss: false,
                                 pauseOnHover: true,
                                 draggable: true,
                                 draggablePercent: 0.4,
                                 showCloseButtonOnHover: false,
                                 hideProgressBar: true,
                                 closeButton: 'button',
                                 icon: true,
                                 rtl: false,
                               })
                             })
                           }

                          }
       },
 
     atras1(){
 
       this.showGeneral=false;
       this.showTypeNegocio=true;
       this.title="Paso 1 de 5 - Tipo de negocio"
       this.size="sm"
   
 
     },
 
 
 
     atras2(){
 
       this.showGeneral=true;
       this.showInfoPermisoUbicacion=false;
       this.title="Paso 2 de 5 - Información"
       this.size="sm"
  
 
       },
 
       atras4(){
 
         if(this.typeNegocio === "virtual"){
 
           this.atras2();
 
         }else{
 
           this.showConfirmacion=false;
         this.showUbicacion=true;
         this.title="Paso 4 de 5 - Ubicación"
         this.size="sm"
 
         }
     
       },
 
       atras3(){
 
         this.showInfoPermisoUbicacion=true;
       this.showUbicacion=false;
       this.title="Paso 3 de 5 - Permisos"
       this.size="sm"
 
       },
 
       
     onChange(event) {
 
    
          this.typeNegocio = event.target.value;
          
          if(this.typeNegocio === "fisico"){


            this.cargarScript();


          }
 
 this.hideTypeNegocio();
 
 
 },

 
 cargarScript(){

// As an instance method inside a component
this.$loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyC_a_0lKNN1bFwUzAamxrJE8GEdAU6iEnE")
.then(() => {
 // Script is loaded, do something
 this.instanceMap()
})
.catch(() => {
 // Failed to fetch script
this.cargarScript();
});

},
 
 hidePermiso(){
       this.showGeneral=false;
       this.showTypeNegocio=false;
       this.showInfoPermisoUbicacion=false;
       this.showUbicacion=true;
       this.title="Paso 4 de 5 - Ubicación"
       this.size="md";
 
   
 
       if(!("geolocation" in navigator)) {
 
           this.$toast.error("La geolocalización no está soportado para este navegador", {
               position: 'top-right',
               timeout: 3010,
               closeOnClick: true,
               pauseOnFocusLoss: false,
               pauseOnHover: true,
               draggable: true,
               draggablePercent: 0.4,
               showCloseButtonOnHover: false,
               hideProgressBar: true,
               closeButton: 'button',
               icon: true,
               rtl: false,
             });
 
             this.$refs.modalNegocios.hide();
 
 
             return;
           }
 
           // get position
         navigator.geolocation.getCurrentPosition(pos => {
 
             this.center = {
                 lat: pos.coords.latitude,
                 lng: pos.coords.longitude
               };
 
             this.markers =
                 [
                     {
                         position: {
                             lat: pos.coords.latitude, lng: pos.coords.longitude
                         },
                     }, // Along list of clusters
                 ]
 
 
           
                 this.geocoder
                       .geocode({ location: this.center })
                       .then((response) => {
 
                
                         if (response.results[0]) {
 
                        
                           this.plusCode=response.results[0].formatted_address
                     
                        
                         }
                       })
                       .catch((e) => console.log("Geocoder failed due to: " + e));
 
 
 
             }, err => {
 
             this.$toast.error(err.message, {
             position: 'top-right',
             timeout: 3010,
             closeOnClick: true,
             pauseOnFocusLoss: false,
             pauseOnHover: true,
             draggable: true,
             draggablePercent: 0.4,
             showCloseButtonOnHover: false,
             hideProgressBar: true,
             closeButton: 'button',
             icon: true,
             rtl: false,
             })
 
 
 
 
             })
 
     },
     onSubmitGeneral(event){
       event.preventDefault();
 
       if(Number(this.description.length) > Number(this.maxChar)){
 
           this.description="";
 
             this.$toast.error("El texto de la descripción sobrepasa el límite permitido de 150 caracteres", {
               position: 'top-right',
               timeout: 3010,
               closeOnClick: true,
               pauseOnFocusLoss: false,
               pauseOnHover: true,
               draggable: true,
               draggablePercent: 0.4,
               showCloseButtonOnHover: false,
               hideProgressBar: true,
               closeButton: 'button',
               icon: true,
               rtl: false,
             })
 
 }else{
 
       if(this.typeNegocio === "virtual"){
 
         this.showGeneral=false;
         this.showTypeNegocio=false;
         this.showInfoPermisoUbicacion=false;
         this.showUbicacion=false;
         this.showConfirmacion=true;
         this.title="Paso 5 de 5 - Confirmación"
         this.size="sm"
 
         this.address="";
         this.plusCode="";
         this.center= {};
         this.markers= [];
 
 
       }else{
 
           this.showGeneral=false;
           this.showTypeNegocio=false;
           this.showInfoPermisoUbicacion=true;
           this.title="Paso 3 de 5 - Permisos"
           this.size="sm"
 
       }
     }
   
     },
 
     hideTypeNegocio(){
       this.showGeneral=true;
       this.showTypeNegocio=false;
       this.title="Paso 2 de 5 - Información"
       this.size="sm"
       this.name="";
       this.description="";
 
     },
 
     onSubmitUbicacion(event){
       event.preventDefault()
       this.showGeneral=false;
       this.showTypeNegocio=false;
       this.showInfoPermisoUbicacion=false;
       this.showUbicacion=false;
       this.showConfirmacion=true;
       this.title="Paso 5 de 5 - Confirmación"
       this.size="sm"
     },
     handleMarkerDrag(e) {
      
 
      this.center = {
            lat: Number(e.latLng.lat()),
            lng: Number(e.latLng.lng())
          };
 
 
 
            this.geocoder
              .geocode({ location: this.center })
              .then((response) => {
 
 
                if (response.results[0]) {
 
                  this.plusCode=response.results[0].formatted_address
 
              
                }
              })
              .catch((e) => console.log("Geocoder failed due to: " + e));
 
 
 
     
    },
 
    mark(event) {
 
 
 
          this.center = {
            lat: Number(event.latLng.lat()),
            lng: Number(event.latLng.lng())
          };
 
          this.markers =
            [
                {
                    position: {
                        lat: Number(event.latLng.lat()), lng: Number(event.latLng.lng())
                    },
                }, // Along list of clusters
            ]
 
 
         
 
            this.geocoder
              .geocode({ location: this.center })
              .then((response) => {
 
 
                if (response.results[0]) {
 
                  this.plusCode=response.results[0].formatted_address
 
              
                }
              })
              .catch((e) => console.log("Geocoder failed due to: " + e));
 
 
 
    },
 
    onSubmitRegisterNegocio(event) {
       event.preventDefault();
 
 
       if(Number(this.description.length) > Number(this.maxChar)){
 
           this.description="";
 
             this.$toast.error("El texto de la descripción sobrepasa el límite permitido de 150 caracteres", {
               position: 'top-right',
               timeout: 3010,
               closeOnClick: true,
               pauseOnFocusLoss: false,
               pauseOnHover: true,
               draggable: true,
               draggablePercent: 0.4,
               showCloseButtonOnHover: false,
               hideProgressBar: true,
               closeButton: 'button',
               icon: true,
               rtl: false,
             })
 
           }else{
 
           
 
         this.isActive=true;
       
          const local_json = {
            userId: this.userId,  name: this.name,description: this.description,typeLocal: this.typeNegocio, pin: this.pin
           }
 
 
           const ubicacion_json = {
            userIdUser:this.userIdUser, idAdmin:this.idAdmin, address: this.address,latitude: this.center.lat,longitude: this.center.lng, plusCode:this.plusCode, alias:this.alias
           }
 
 
 
         const local_string = JSON.stringify(local_json)
         const ubicacion_string = JSON.stringify(ubicacion_json)
 
 
         const dataEncripted1 = this.$encryptAdmin.encrypt(local_string)
         const dataEncripted2 = this.$encryptAdmin.encrypt(ubicacion_string)
 
 
         document.getElementById('btn_finalizar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Registrando'
 
      
       this.$https.post('/administracion/saveLocals/', { tokenAuth: this.tokenAuth, dataEncripted1: dataEncripted1, dataEncripted2:dataEncripted2 }).then(response => {
         document.getElementById('btn_finalizar').innerHTML = 'Finalizar';
         this.isActive=false;
 
       if (response.data.code == 200) {

      
 
         this.$toast.success(response.data.message, {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         });
 
      
 
         
 
         this.$eventBus.$emit('reiniciarModalNegocioAdmin')
  
 
       } else {
 
 
 
       if (response.data.code == 401) {
 
         this.$toast.error(response.data.message, {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         })
 
         localStorage.removeItem('userData')
 
 
         this.$router.push({ name: 'auth-login' })
       } else {
 
         if(response.data.code == 404){
 
           this.$refs.modalNegocios.hide();
 
           this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                                })
 
 
         }else{
 
           if(response.data.code == 501){
 
             this.$refs.modalNegocios.hide();
 
          
             this.$toast.error(response.data.message, {
               position: 'top-right',
               timeout: 3010,
               closeOnClick: true,
               pauseOnFocusLoss: false,
               pauseOnHover: true,
               draggable: true,
               draggablePercent: 0.4,
               showCloseButtonOnHover: false,
               hideProgressBar: true,
               closeButton: 'button',
               icon: true,
               rtl: false,
             })
 
           }else{
             this.$toast.error(response.data.message, {
               position: 'top-right',
               timeout: 3010,
               closeOnClick: true,
               pauseOnFocusLoss: false,
               pauseOnHover: true,
               draggable: true,
               draggablePercent: 0.4,
               showCloseButtonOnHover: false,
               hideProgressBar: true,
               closeButton: 'button',
               icon: true,
               rtl: false,
             })
           }
 
           
         }
         
       }
       }
       }).catch(error => {
         this.$toast.error("Ocurrio un error " + error, {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         })
       })
 
           }
 
 
     },
          
       
         },
       }
       </script>
       
       <style lang="scss">
       
       
       
       </style>
       