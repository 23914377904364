<template>

    <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="loading" style="margin-bottom:15px"> 
    
      <b-card>
    
   
    
      <b-skeleton-table
                      :rows="3"
                      :columns="3"
                      :table-props="{ bordered: true, striped: true }"
                    ></b-skeleton-table>
                  </b-card>
        </b-col>
        
        <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-else-if="code200" style="  overflow: auto;">
          <b-card>
           
          <table class="table tablesorter"  id="datatableRetiros" >
                          <thead class="thead-light">
                            <tr>
                              <th>Fecha</th>
                              <th>Monto transferido</th>
                              
                            
                              <th>Status</th>
    
                              <th>Referencia</th>
                            </tr>
                          </thead>
                          <tbody>
        
        
                            <tr v-for="transaccion in transactions" :key="transaccion.idTransaction">
        
                              <td> {{ transaccion.dateFormated }}</td>
                              <td> $ {{ transaccion.mount_transferir }}</td>
                             
              
    
                              
                                <td v-if="transaccion.status === 'Pagada'">
                                     <b-badge variant="success">
                                        Pagado
                                      </b-badge>
                                  
                                  </td>
                                  <td v-else-if="transaccion.status === 'Pendiente'">

                                     <b-badge variant="warning">
                                        Pendiente
                                      </b-badge>
                                   
                                    
                                    </td>

                                       <td v-else>

                                     <b-badge variant="danger">
                                        Rechazado
                                      </b-badge>
                                   
                                    
                                    </td>
                                    <td> {{ transaccion.ref }}</td>
                            </tr>
                          </tbody>
                        </table>
        
              
                      </b-card>
          </b-col>
        
          <b-col  cols="12" sm="12" md="12" xl="12" lg="12" v-else-if="empty" align="center">
    
            <b-card>
      
    
                  <svg class="icons_colors" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="17. Withdraw" id="_17._Withdraw"><path d="M31 0H1A1 1 0 0 0 0 1V13a1 1 0 0 0 1 1H6V31a1 1 0 0 0 1 1H25a1 1 0 0 0 1-1V14h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM2 2H30V4H2zM24 30H8V10H24zm6-18H26V10h1a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2H6v2H2V6H30z"/><path d="M29 23a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/><path d="M3 16a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V17a1 1 0 0 0-1-1z"/><path d="M21 16h-.18A3 3 0 0 0 15 17a1 1 0 0 1-2 0V15a1 1 0 0 0-2 0v1a1 1 0 0 0 0 2h.18A3 3 0 0 0 17 17a1 1 0 0 1 2 0v2a1 1 0 0 0 2 0V18a1 1 0 0 0 0-2z"/><path d="M16 23a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/></g></svg>                    
                                                          
        
                                  <p class="errorTexto">
                                Este usuario aún no ha realizado retiros de dinero
                              </p>
        
        
                            </b-card>
          </b-col>
            
        </template>
            
            <script>
            import {
              BButton, BRow, BCol,BSkeleton,BSkeletonTable, BCard, BBadge
            } from 'bootstrap-vue'
            import "jquery/dist/jquery.min.js";
            
            import $ from "jquery";
            import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
            import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
            
            export default {
              components: {
                BButton,
                BBadge,
                BSkeleton,
                BSkeletonTable,
                BRow,
                BCol,
                BCard
              },
              props: ['userId','tokenAuth', 'idAdmin', 'userIdUser'],
              data() {
            
    
            
                return {
                  code200: false,
                  loading: true,
                   empty:false,
                   transactions:[],
    
            
            
                }
            
            
              },
              computed: {
            
              },
              watch: {
            
              },
              mounted() {
        
        
                this.loadTable();
        
        
            
            
            
            
              },
              methods: {
            
                 loadTable(){
                  const data_json = { userId: this.userId, idAdmin: this.idAdmin, idAdmin: this.idAdmin , userIdUser: this.userIdUser }
                  const data_string = JSON.stringify(data_json)
        
            
                  const dataEncripted = this.$encryptAdmin.encrypt(data_string);
        
                    this.$https.post('/administracion/listAllRetiros/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        
                      this.loading=false;
    
    
                      if (response.data.code == 200) {
                        this.transactions= response.data["transactions"];
                    
                        setTimeout(() => {
                              $("#datatableRetiros").DataTable({
                                responsive: true,
                                "ordering": false,

                                lengthMenu: [
                                  [5,15,25, 35, 50, -1],
                                  [5,15,25, 35, 50, "All"],
                                ],
                                pageLength: 5, 
                                   "language": {
                                                      "search": "Buscar:",
                                                      "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                        "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                      "searchPlaceholder": "Ingrese algún dato",
                                                      "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                      "processing": "Buscando. Espere por favor...",
                                                      "sLengthMenu":     "Mostrar _MENU_ registros",
                                                      "oPaginate": {
                                                              "sFirst":    "Primero",
                                                              "sLast":     "Último",
                                                              "sNext":     "Siguiente",
                                                              "sPrevious": "Anterior"
                                                          },
        
                                                      }
                              });
                            });
                                    
                         
                        this.code200=true;
        
                      
                        
        
        
                        
                      } else {
                        
    
        
                        if (response.data.code == 401) {
        
                            this.$toast.error(response.data.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })
        
                            
                            localStorage.removeItem('userData')

                            
        
                            
        
        
        
        
                            
                            
        
                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            } else {
        
                              if (response.data.code == 403) {
        
                                this.$router.push({ name: 'dashboard-analytics' })
        
                                }else{
                                  if (response.data.code == 404) {
        
        
        
                                    this.empty=true;
        
                                    }else{
        
                                      this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                      })
        
                                      this.code200 = false
        
                                    }
        
                                }
        
                      
        
        
        
                            } 
                          
                        
                      }
                    }).catch(error => {
                        this.loadTable();
                    })
                
                }
            
            
              },
            }
            </script>
            
            <style lang="scss">
            
            
            </style>