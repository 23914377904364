<template>

    <b-row>
      

        <b-col cols="12" sm="12" md="12" lg="12" xl="12">

          <b-card>

                              

            <listado-negocios
              :key="componentKeyListadoNegociosAdmin"
              :user-id="userId"
              :token-auth="tokenAuth"
              :id-admin="idAdmin"
            />

            </b-card>


        </b-col>

      
    </b-row>
  
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BFormSelect, BForm, BFormInput
      } from 'bootstrap-vue'
   
      import ListadoNegocios from './ListadoNegocios.vue'
      export default {
        components: {
          BFormSelect,
          ListadoNegocios,
          BForm,
      
          BButton,
          BRow,
          BCol,
          BCard,
          BFormInput
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['idAdmin','tokenAuth','userId'],
        data() {
      
      
          return {
            componentKeyCantidadNegociosAdmin:0,
            componentKeyCantidadNegociosFreeAdmin:1,
            componentKeyCantidadNegociosBasicAdmin:2,
            componentKeyCantidadNegociosEliteAdmin:3,
            componentKeyCantidadNegociosEmprendedorAdmin:4,
            componentKeyCantidadNegociosBusinessAdmin:5,
            componentKeyListadoNegociosAdmin:6,
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

          this.$eventBus.$on('reiniciarListadoNegociosAdmin', () => {
            this.componentKeyListadoNegociosAdmin += 7000
       
          });

  
         this.$eventBus.$on('reiniciarCantidadNegociosAdmin', () => {
            this.componentKeyCantidadNegociosAdmin += 1000

         });

         this.$eventBus.$on('reiniciarCantidadNegociosFreeAdmin', () => {
            this.componentKeyCantidadNegociosFreeAdmin += 2000

         });

         this.$eventBus.$on('reiniciarCantidadNegociosBasicAdmin', () => {
            this.componentKeyCantidadNegociosBasicAdmin += 3000

         });

         this.$eventBus.$on('reiniciarCantidadNegociosEliteAdmin', () => {
            this.componentKeyCantidadNegociosEliteAdmin += 4000

         });
         this.$eventBus.$on('reiniciarCantidadNegociosEmprendedorAdmin', () => {
            this.componentKeyCantidadNegociosEmprendedorAdmin += 5000

         });
         this.$eventBus.$on('reiniciarCantidadNegociosBusinessAdmin', () => {
            this.componentKeyCantidadNegociosBusinessAdmin += 6000

         });

         
      
        },
        methods: {

      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      