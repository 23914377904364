<template>

    <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="loading" style="margin-bottom:15px"> 
    
      <b-card>
    
    
    
      <b-skeleton-table
                      :rows="3"
                      :columns="4"
                      :table-props="{ bordered: true, striped: true }"
                    ></b-skeleton-table>
                  </b-card>
        </b-col>
        
        <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-else-if="code200" style="  overflow: auto;">
    
            <pdf
                v-if="showPdf"
            :key="componentKeyPdf"
            :user-id="userId"
             :token-auth="tokenAuth"
             :id-transaction="idTransaction"
             :date-formated="dateFormated"
             :display-name-send-money="displayNameSendMoney"
             :display-name-receive-money="displayNameReceiveMoney"
             :source="source"
             :token="token"
             :mount="mount"
             :photo-send-money="photoSendMoney"
             :photo-receive-money="photoReceiveMoney"
          />


      <b-modal
          ref="modalDatosTransaccion"
          title="Detalles de la transacción"
          size="lg"
          centered
          hide-footer
        >
          <div class="d-block text-center">
       
    
            <b-row >
    
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
    
                <p style="text-align:left"><b>Fecha: </b> {{dateFormated}}</p>
    
              </b-col>
    
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <p style="text-align:left"><b>Origen: </b> {{source}}</p>
              </b-col>
    
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <p style="text-align:left"><b>ID transacción: </b> {{idTransaction}} </p>
                </b-col>
    
    
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <p style="text-align:left"><b>Monto real: </b> ${{mountReal}} USD</p>
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <p style="text-align:left"><b>Monto con comisión: </b> ${{mount}} USD</p>
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <p style="text-align:left"><b>Currency: </b> {{currency}} </p>
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <p style="text-align:left"><b>Balance: </b> ${{balance}} USD</p>
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <p style="text-align:left"><b>Before Balance: </b> ${{beforeBalance}} USD</p>
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <p style="text-align:left"><b>UserID de quién envió el pago: </b> </p>
                  <p style="text-align:left">{{userIdWhoPay}}</p>
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <p style="text-align:left"><b>UserID de quién recibió el pago: </b> </p>
                  <p style="text-align:left">{{userIdWhoReceive}}</p>
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <p style="text-align:left"><b>Email: </b> {{email}} </p>
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <p style="text-align:left"><b>Celular: </b> {{phone}} </p>
                </b-col>
    
    
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <p style="text-align:left"><b>Usuario quien envió el pago: </b> </p>
                  <p style="text-align:left">{{displayNameSendMoney}}</p>
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <p style="text-align:left"><b>Usuario quien recibió el pago: </b> </p>
                  <p style="text-align:left">{{displayNameReceiveMoney}}</p>
                </b-col>
    
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <p style="text-align:left"><b>Descripción del pago: </b> </p>
                  <p style="text-align:left">{{description}}</p>
                </b-col>
    
    
    
              </b-row>
    
              </div>
    
              </b-modal>
    
          <b-card style="margin-bottom:0px">
      
          <table class="table tablesorter"  id="datatableMovimientos" >
                          <thead class="thead-light">
                            <tr>
                              <th>Fecha</th>
                   
                              <th>Transaccion</th>
                              <th>Monto real</th>
                              <th>Monto con comisión</th>
                              <th>Acción</th>
                            
                            </tr>
                          </thead>
                          <tbody>
        
        
                            <tr v-for="transaccion in transactions" :key="transaccion.idTransaction">
        
                              <td> {{ transaccion.dateFormated }}</td>
                          
                              <td> {{ transaccion.source }}</td>
              
    
                                <td> $ {{ transaccion.mount }}</td>
                                <td> $ {{ transaccion.mount2 }}</td>
    
    
                               
                                <td>            <b-button size="sm"  block  variant="primary"  @click="verDatos(transaccion.currency,transaccion.userIdWhoPay,transaccion.userIdWhoReceive,transaccion.email,transaccion.phone,transaccion.idTransaction,transaccion.displayNameSendMoney,transaccion.displayNameReceiveMoney,transaccion.description,transaccion.beforeBalance,transaccion.balance,transaccion.dateFormated, transaccion.source, transaccion.mount, transaccion.mount2 )" > 
                                              <feather-icon
                                              icon="EyeIcon"
                                              />  </b-button>   <b-button  size="sm" block
                                                  variant="outline-secondary"
                                                  v-b-tooltip.hover.left="'Descargar comprobante'"
                                                 @click="downloadComprobante(transaccion.idTransaction, transaccion.dateFormated,  transaccion.displayNameReceiveMoney, transaccion.displayNameSendMoney,  transaccion.token, transaccion.source, transaccion.mount, transaccion.photoReceiveMoney, transaccion.photoSendMoney)"
                                                >

                                                <feather-icon
                                                icon="DownloadIcon"
                                                size="16"
                                               
                                                />

                                                </b-button></td>
                            </tr>
                          </tbody>
                        </table>
        
              
                      </b-card>
          </b-col>
        
          <b-col  cols="12" sm="12" md="12" xl="12" lg="12" v-else-if="empty" align="center">
    
            <b-card>
      
    
                  <svg class="icons_colors" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="17. Withdraw" id="_17._Withdraw"><path d="M31 0H1A1 1 0 0 0 0 1V13a1 1 0 0 0 1 1H6V31a1 1 0 0 0 1 1H25a1 1 0 0 0 1-1V14h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM2 2H30V4H2zM24 30H8V10H24zm6-18H26V10h1a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2H6v2H2V6H30z"/><path d="M29 23a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/><path d="M3 16a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V17a1 1 0 0 0-1-1z"/><path d="M21 16h-.18A3 3 0 0 0 15 17a1 1 0 0 1-2 0V15a1 1 0 0 0-2 0v1a1 1 0 0 0 0 2h.18A3 3 0 0 0 17 17a1 1 0 0 1 2 0v2a1 1 0 0 0 2 0V18a1 1 0 0 0 0-2z"/><path d="M16 23a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/></g></svg>                    
                                                          
        
                                  <p class="errorTexto">
                                No se encontraron movimientos
                              </p>
        
        
                            </b-card>
          </b-col>
            
        </template>
            
            <script>
            import {
              BButton, BRow, BCol,BSkeleton,BSkeletonTable, BCard, VBTooltip,VBPopover,
            } from 'bootstrap-vue'
            import "jquery/dist/jquery.min.js";
            import Pdf from './Pdf.vue'
            import $ from "jquery";
            import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
            import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
            
            export default {
              components: {
                BButton,
                BSkeleton,
                BSkeletonTable,
                BRow,
                BCol,
                BCard,
                Pdf
              },
              directives: {
                'b-popover': VBPopover,
                'b-tooltip': VBTooltip,
            },
              props: ['userId','tokenAuth', 'idAdmin', 'userIdUser'],
              data() {
            
    
            
                return {
                  code200: false,
                  loading: true,
                   empty:false,
                   transactions:[],
                   dateFormated:"", source:"", mountReal:"", mount:"",
                   userIdWhoPay:"",
                    userIdWhoReceive:"",
                    email:"",
                    phone:"",
                    idTransaction:"",
                    displayNameSendMoney:"",
                    displayNameReceiveMoney:"",
                    description:"",
                    beforeBalance:"",
                    currency:"",
                    balance:"",
                    showPdf:false,
                    photoReceiveMoney:"",
                    photoSendMoney:"",
                    token:"",
                    componentKeyPdf:100,
                }
            
            
              },
              computed: {
            
              },
              watch: {
            
              },
              mounted() {
        
        
                this.loadTable();
        
        
                this.$eventBus.$on('reiniciarPdf', () => {
                    this.componentKeyPdf += 1
                    this.showPdf=false;
                    });
            
            
            
              },
              methods: {
                downloadComprobante(id, date, displayNameReceiveMoney, displayNameSendMoney, token, source, mount, photoReceiveMoney, photoSendMoney){

            this.idTransaction=id;
            this.showPdf=true;
            this.dateFormated=date;
            this.displayNameReceiveMoney=displayNameReceiveMoney;
            this.displayNameSendMoney=displayNameSendMoney;
            this.mount=mount;
            this.photoReceiveMoney=photoReceiveMoney;
            this.photoSendMoney= photoSendMoney;
            this.token=token;
            this.source=source;
            },
    
                verDatos(currency, userIdWhoPay,userIdWhoReceive,email,phone,idTransaction,displayNameSendMoney,displayNameReceiveMoney,description,beforeBalance,balance,dateFormated, source, mountReal, mount){
    
               this.dateFormated=dateFormated; 
               this.source=source; 
               this.mountReal=mountReal;
               this.mount=mount;
               this.currency=currency;
    
              
              this.userIdWhoPay= userIdWhoPay;
              this.userIdWhoReceive= userIdWhoReceive;
              this.email= email;
              this.phone= phone;
              this.idTransaction= idTransaction;
              this.displayNameSendMoney= displayNameSendMoney;
              this.displayNameReceiveMoney= displayNameReceiveMoney;
              this.description= description;
              this.beforeBalance= beforeBalance;
              this.balance= balance;
    
                  this.$refs.modalDatosTransaccion.show();
                },
            
                 loadTable(){
                  const data_json = { userId: this.userId, idAdmin: this.idAdmin , userIdUser: this.userIdUser, type: "Send Money" }
                  const data_string = JSON.stringify(data_json)
        
            
                  const dataEncripted = this.$encryptAdmin.encrypt(data_string);
        
                    this.$https.post('/administracion/listMovimientos/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        
                      this.loading=false;
    
        
    
                  
                      if (response.data.code == 200) {
                        this.transactions= response.data["transactions"];
                    
                        setTimeout(() => {
                              $("#datatableMovimientos").DataTable({
                                responsive: true,
                                "ordering": false,
                                columnDefs: [
                                                        { responsivePriority: 1, targets: 0 },
                                                        { responsivePriority: 2, targets: -1 }
                                                    ],
                                lengthMenu: [
                                  [5,15,25, 35, 50, -1],
                                  [5,15,25, 35, 50, "All"],
                                ],
                                pageLength: 5, 
                                   "language": {
                                                      "search": "Buscar:",
                                                      "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                        "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                      "searchPlaceholder": "Ingrese algún dato",
                                                      "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                      "processing": "Buscando. Espere por favor...",
                                                      "sLengthMenu":     "Mostrar _MENU_ registros",
                                                      "oPaginate": {
                                                              "sFirst":    "Primero",
                                                              "sLast":     "Último",
                                                              "sNext":     "Siguiente",
                                                              "sPrevious": "Anterior"
                                                          },
        
                                                      }
                              });
                            });
                                    
                         
                        this.code200=true;
        
                      
                        
        
        
                        
                      } else {
                        
    
        
                        if (response.data.code == 401) {
        
                            this.$toast.error(response.data.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })
        
                            
                            localStorage.removeItem('userData')
    
                            
        
                            
        
        
        
        
                            
                            
        
                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            } else {
        
                              if (response.data.code == 403) {
        
                                this.$router.push({ name: 'dashboard-analytics' })
        
                                }else{
                                  if (response.data.code == 404) {
        
        
        
                                    this.empty=true;
        
                                    }else{
        
                                      this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                      })
        
                                      this.code200 = false
        
                                    }
        
                                }
        
                      
        
        
        
                            } 
                          
                        
                      }
                    }).catch(error => {
                        this.loadTable();
                    })
                
                }
            
            
              },
            }
            </script>
            
            <style lang="scss">
            
            
            </style>