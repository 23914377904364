<template>

    <div>

    <b-modal
      ref="modalBanco"
      centered
      size="sm"
      hide-footer
      @hidden="closeModalRecargasBanco"
      :title="title"
    >
      <div class="d-block text-center">

  
          <b-row v-if="showBanks">

        

       

<b-col cols="12" sm="12" md="12" lg="12" xl="12" style=" margin-bottom: 10px;">


  <div class="radio-tile-group">
                 
    
          <div class="input-container" v-for="banco in banks" :key="banco.id">
                    <input :id="banco.id" class="radio-button" type="radio" @click="onChangeBanco(banco.id, banco.bank)" :value="banco.id" name="radio" />
                    <div class="radio-tile">

                      <label class="radio-tile-label" style="margin-bottom: 6px;">{{banco.bank}}</label>
                      <div class="icon walk-icon">

                             <img style="width: 50px;" :alt="banco.bank" :src="banco.logo">                 
                      </div>

                   
                   
                    </div>
                  </div>

        
      
         </div>

  </b-col>



    


</b-row>




        <b-row v-else>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
     

            <b-row>

       

                  <b-form
                            autocomplete="off"
                            @submit.prevent="onSubmitBanco"
                            
                        >

                        <b-row>


                  <b-col cols="12" sm="12" md="12" lg="12" xl="12"  >

                    <p class="textLabel" >Ingrese el nro. de comprobante (Solo números *):</p>

                    <b-form-input
                     type="text"
                      v-model="referencia"
                          placeholder="Ingrese el número de comprobante"
                          required
                          autofocus
                          :readonly="isDisabled"
                      />

                      
                     

                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12"  style="margin-top: 10px;">
                      <b-alert
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                     

                                
                                  <p style="text-align: left;font-size:13px; margin-bottom:0px">En el caso de que su referencia contenga caracteres especiales o alfabéticos, comuníquese con servicio al cliente para realizar su recarga.</p>

                     
                        </div>
                      </b-alert>
                    </b-col>

                    <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        style="margin-top: 10px;"
                      >
                      <p class="textLabel" >Suba la imagen de la transferencia (*):</p>
                      
                        <b-form-file
                          id="depositoFoto"
                          v-model="depositoFoto"
                          required
                          :disabled="isDisabled"
                          accept="image/jpeg, image/jpg, image/png"
                          placeholder="Seleccione una imagen o suéltelo aquí..."
                          drop-placeholder="Suelte la imagen aqui..."
                          @change="previewImage1"
                        />

                        <p style="margin: 0;font-size: 12px;display:flex">{{ uploadValue1.toFixed()+"%" }}
                          <progress
                            style="width:100%"
                            :value="uploadValue1"
                            max="100"
                          />  </p>

                      </b-col>


                    <b-col
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            style="margin-top:10px"
                            >

                        

                            <p
                                class="textLabel"
                                style="text-align: center;margin-top: 0px;margin-bottom: 10px;"
                            >
                                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

                                <PincodeInput
                                    v-model="pin"
                                    placeholder="-"
                                    :length="6"
                                    :autofocus="false"
                                    :secure="true"
                                    :disabled="isDisabled"
                                
                                    required
                                />

                                </b-col>

                                <b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center" >
                        
                                  <b-button block
                                  :disabled="isDisabled"
                                                            @click="atras2"
                                                            variant="outline-secondary"
                                                            style="margin-right: 15px;"
                                                          >
                                                          <feather-icon

                                                              icon="ArrowLeftCircleIcon"
                                                              size="13"
                                                              style= "margin-right:10px"
                                                              />
                                                            Atras 

                                                          </b-button>

                                </b-col>
                                   

                                <b-col
                                cols="6"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                            >

                                            <b-button
                                            block
                                                id="btn_solicitar"
                                                ref="btn_solicitar"
                                                type="submit"
                                                variant="primary"
                                                :disabled="isDisabled"
                                            >

                                            Enviar

                                            </b-button>

                                            </b-col>


                                          </b-row>


                                          </b-form>

               

            </b-row>
            
       

        </b-col>



          </b-row>



        </div>


        </b-modal>
  
  
  </div>
  
  </template>
  
  <script>
  import {
   BButton, BRow, BCol, BCard, BForm, BAlert,  VBTooltip,VBPopover,BAvatar,BFormInput,BFormFile
  } from 'bootstrap-vue'

  import PincodeInput from 'vue-pincode-input'
  import domtoimage from "dom-to-image-more";
import 'firebase/compat/storage'

  export default {
    components: {
      BButton,
      BFormFile,
      BForm,
      BFormInput,
      BAvatar,
      BRow,
      BCol,
      BAlert,
      BCard,
      PincodeInput,
      domtoimage
    },
    props: ['userId','tokenAuth', 'banks', 'userIdUser', 'idAdmin'],
    directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    data() {
  
                          
  
      return {



        title:"Recargar por transferencia",
       isDisabled:false,
       pin:"",
       showBanks:true,
       bankId:"",
       nameBank:"",
       depositoFoto: [],
       uploadValue1: 0,
       isDisabledDescargar:false,
       referencia:"",


      
      }
    },
    computed: {
  
  
  
    },
    watch: {
  
  
  
    },
    mounted() {
  
  
       

        this.$refs.modalBanco.show()
  
        
  
  
  
    },
    methods: {

      previewImage1(event) {
      const FileSizeBanner = event.target.files[0].size / 1024 / 1024

      if (FileSizeBanner > 3) {
        this.depositoFoto = [];

        this.$toast.error('La imagen debe pesar menos de 3mb', {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      }
    },




      atras2(){



    this.showBanks=true;
    },

   

      onChangeBanco(id, nameBank){

        this.referencia="";
this.pin="";
this.depositoFoto=null;
       this.uploadValue1=0,


        this.bankId=id;
        this.nameBank=nameBank;

        this.showBanks=false;



      },

        closeModalRecargasBanco(){

     
         
            this.$eventBus.$emit('reiniciarAdminBanco');
    
     


    },


    onSubmitBanco(event) {
      event.preventDefault()

      this.isDisabled = true
      this.uploadValue1 = 0;

      const d1 = new Date();

      let nameFile= this.userId+"_"+d1.getTime()+"_"+this.depositoFoto.name;
        let dir= 'depositosBancarios/'+this.userId;

        const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).put(this.depositoFoto);

     
      storageRef.on('state_changed', snapshot => {
        this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

        document.getElementById('btn_solicitar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Subiendo'
      }, error => {
        this.$toast.error("Ocurrió un error inesperado".message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        this.isDisabled = false
        document.getElementById('btn_solicitar').innerHTML = 'Enviar'
      },
      () => {
        this.uploadValue1 = 100
        storageRef.snapshot.ref.getDownloadURL().then(url => {
          const data_json = { userId: this.userId, pin: this.pin,userIdUser:this.userIdUser,idAdmin:this.idAdmin }
          const data_string = JSON.stringify(data_json)

          const dataEncripted = this.$encryptAdmin.encrypt(data_string)


          const data_json2 = {referencia: this.referencia, bankId:this.bankId,nameBank: this.nameBank }
          const data_string2 = JSON.stringify(data_json2)

          const dataEncripted2 = this.$encryptAdmin.encrypt(data_string2)

          document.getElementById('btn_solicitar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Enviando'

          this.$https.post('/administracion/sendDeposito/', { tokenAuth: this.tokenAuth,url:url, data: dataEncripted, data2:dataEncripted2 }).then(response => {
            document.getElementById('btn_solicitar').innerHTML = 'Enviar';
            this.isDisabled = false
            if (response.data.code == 200) {
             

                  this.$toast.success(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                });

                this.$refs.modalBanco.hide();
                this.$eventBus.$emit('reiniciarCountTransferenciasAdmin')
                this.$eventBus.$emit('reiniciarDepositosAdmin')
                

          
              
            } else {
              this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

              if (response.data.code == 401) {
                this.$refs.modalPhoto.hide()

                
                localStorage.removeItem('userData')

                

                
                

           

                
                

                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              }else{

                if( response.data.code==407){

                  this.$refs.modalBanco.hide();

                }else{

                  if(response.data.code == 403){

                                        
                  this.$refs.modalBanco.hide();



                    }else{
                      if(response.data.code===503){
                        this.pin="";

                      }
                    }


                }
               
              }
            }
          }).catch(error => {
            this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          })
        })
      })
    },
        

    
    }
  }
  </script>
  
  <style lang="scss">
  
  
  
  
  
  </style>
  