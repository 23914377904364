<template>

    <b-row v-if="loading" style="margin-bottom:15px"> 
    
      <b-col  cols="10" sm="10" md="10" xl="10" lg="10">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
          <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    />  Listado de publicaciones por aprobar
            </h4>
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="20px"></b-skeleton>
    
    </b-col>
    
    
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    
    </b-col>
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    </b-col>
    
    <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
    
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
        </b-col>
    
        <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
    
        <b-skeleton width="100%"  height="30px"></b-skeleton>
    
        </b-col>
    
        <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
        </b-col>
    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">
    
    
    
    
                      <b-skeleton-table
                  :rows="2"
                  :columns="columnas"
                  :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
    
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
    <b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>
    
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
    <b-skeleton width="100%"  height="30px"></b-skeleton>
    
    
    </b-col>
    
    
    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
    
    
    </b-col>
    
    
    
    </b-row>
    
    <b-row v-else-if="code200" style="  overflow: auto;">

   
    
    
    
        <b-col  cols="10" sm="10" md="10" xl="10" lg="10">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
          <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    />  Listado de publicaciones por aprobar
            </h4>
    
    </b-col>
    
    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">
    
    
    <p style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                                <feather-icon
                                                                icon="RefreshCcwIcon"
                                                                size="16"
                                                                style="margin-right:5px"
                                                                /> <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block"> Recargar </span> </p>
    
    
    
    </b-col>
    
    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto; padding: 0px; margin-top:15px">
    <table class="table tablesorter"  id="datatableProductos" >
                      <thead class="thead-light">
                        <tr>
                          <th>Fecha</th>
                          <th data-priority="1">Negocio</th>
                          <th>Revisión</th>
                          <th>Titulo</th>
                          <th>Descripción</th>
                          <th>Imágenes</th>
                          <th data-priority="2">Acción</th>
                        
                        </tr>
                      </thead>
                      <tbody>
    
    
                        <tr v-for="producto in productos" :key="producto.id">
    
                          <td> {{ producto.dateFormated }}</td>
                          <td> {{ producto.alias }}</td>
                          <td> 
                            <p style="text-align:left; font-size:12px; margin-bottom:5px" v-if="producto.editTitle">Modificó el título</p>
                            <p style="text-align:left; font-size:12px; margin-bottom:5px" v-if="producto.editImagen">Modificó las imágenes</p>
                            <p style="text-align:left; font-size:12px; margin-bottom:5px" v-if="producto.editDescription">Modificó la descripción</p>
                          </td>
                          <td>  
                            <read-more more-str="Más información" :text="producto.title" link="#" less-str="Menos información" :max-chars="50"></read-more>
                             </td>
                             <td>  

                                <b-button size="sm"
                                @click="showModal(producto.description)"
                                                    variant="outline-secondary"
                                                
                                               
                                                >

                                              
                                    Leer descripción

                                                </b-button>


                        </td>
                
    
                            <td>

                                <b-link    v-if="producto.urlImagen1 != ''" target="_blank" :href="producto.urlImagen1">

                               
                                <b-button
                                style="margin-bottom:10px; margin-right: 5px;"
                                        variant="outline-secondary"
                                       
                                    >

                                    <feather-icon
                                                icon="ImageIcon"
                                                size="18"
                                              
                                                />

                                    </b-button>

                                </b-link>

                                <b-link    v-if="producto.urlImagen2 != ''" target="_blank" :href="producto.urlImagen2">

                                                                        
                                            <b-button
                                            style="margin-bottom:10px;margin-right: 5px;"
                                                    variant="outline-secondary"
                                                
                                               
                                                >

                                              
                                    <feather-icon
                                                icon="ImageIcon"
                                                size="18"
                                              
                                                />

                                                </b-button>

                                            </b-link>

                                            <b-link  v-if="producto.urlImagen3 != ''" target="_blank" :href="producto.urlImagen3">

                                                                        
                                            <b-button
                                            style="margin-bottom:10px;margin-right: 5px;"
                                                    variant="outline-secondary"
                                                
                                                >

                                              
                                    <feather-icon
                                                icon="ImageIcon"
                                                size="18"
                                              
                                                />

                                                </b-button>

                                            </b-link>


                                            <b-link  v-if="producto.urlImagen4 != ''" target="_blank" :href="producto.urlImagen4">

                                                                        
                                            <b-button
                                            style="margin-bottom:10px"
                                                    variant="outline-secondary"
                                                
                                                >

                                               
                                    <feather-icon
                                                icon="ImageIcon"
                                                size="18"
                                              
                                                />

                                                </b-button>

                                            </b-link>

                                </td>
    
                   
                             <td>
    
                              <b-dropdown  dropleft variant="light" size="sm"  no-caret>
                                                  <template  #button-content>
    
                                                  <feather-icon
                                                    icon="MoreVerticalIcon"
                                                    size="25"
                                                    class="MoreVerticalIcon"
                                                    />
                                                    
    
    
                                                  </template>
                                                  <b-dropdown-item @click="aprobar(producto.id, 'Aprobar')">
                                                    <feather-icon
                                                    icon="CheckCircleIcon"
                                                    size="15"
                                                    style="margin-right: 10px;"
                                                    />
    
                                                    Aprobar producto
                                                  </b-dropdown-item>
                                                  <b-dropdown-item @click="rechazar(producto.id, 'Rechazar')">
                                                    <feather-icon
                                                    icon="AlertOctagonIcon"
                                                    size="15"
                                                    style="margin-right: 10px;"
                                                    />
    
                                                    Rechazar producto
                                                  </b-dropdown-item>

                                                  <b-dropdown-item @click="eliminar(producto.id, 'Eliminar')">
                                                    <feather-icon
                                                    icon="TrashIcon"
                                                    size="15"
                                                    style="margin-right: 10px;"
                                                    />
    
                                                    Eliminar producto
                                                  </b-dropdown-item>
                         
                                                </b-dropdown>
                             </td>
    
                  
    
                        </tr>
                      </tbody>
                    </table>
    
                  </b-col>

                  <b-modal
                    ref="modalDescripcionSuscripcion"
                    centered
                    size="sm"
                    ok-only
                    scrollable 
                    hideHeaderClose
                    hideHeader
                    ok-title="Entendido"
                    >
                    <div class="d-block text-center">

                        <div v-html="description">

                    

                        </div>

                

                        </div>

                        </b-modal>

    
    
      </b-row>

      <b-row v-else-if="notAllow">

<b-col  cols="12" sm="12" md="12" xl="12" lg="12" align="center">

  <img style="width:100px;" src="/img/caution.svg" />

  <p style="margin-top:10px; font-size:14px; ">Tu rol no te permite utilizar la siguiente funcionalidad.</p>


  </b-col>

</b-row>
    
    <b-row v-else-if="empty">
      <b-col  cols="12" sm="12" md="12" xl="12" lg="12">
    
    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
          <feather-icon
                    icon="ListIcon"
                    size="21"
                    class="color_icon"
                    />  Listado de publicaciones por aprobar
            </h4>
    
    </b-col>
                          
    
      <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">
              <svg class="icons_colors" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g data-name="17. Withdraw" id="_17._Withdraw"><path d="M31 0H1A1 1 0 0 0 0 1V13a1 1 0 0 0 1 1H6V31a1 1 0 0 0 1 1H25a1 1 0 0 0 1-1V14h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM2 2H30V4H2zM24 30H8V10H24zm6-18H26V10h1a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2H6v2H2V6H30z"/><path d="M29 23a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/><path d="M3 16a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V17a1 1 0 0 0-1-1z"/><path d="M21 16h-.18A3 3 0 0 0 15 17a1 1 0 0 1-2 0V15a1 1 0 0 0-2 0v1a1 1 0 0 0 0 2h.18A3 3 0 0 0 17 17a1 1 0 0 1 2 0v2a1 1 0 0 0 2 0V18a1 1 0 0 0 0-2z"/><path d="M16 23a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V24a1 1 0 0 0-1-1z"/></g></svg>                    
                                                      
    
                              <p class="errorTexto">
                            No se encontraron publicaciones por aprobar
                          </p>
    
                  </b-col>
    
      <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="    text-align: center;">
    
                                <b-button   variant="primary"  @click="refresh()" > 
                                              <feather-icon
                                              icon="RefreshCcwIcon"
                                              size="16"
                                              
                                              style="margin-right:5px; color:#fff"
                                              /> Recargar listado </b-button>
    
    
      </b-col>
      </b-row>
        
    </template>
        
        <script>
        import {
          VBTooltip, BButton,VBPopover,BForm, BRow, BCol,BDropdown,BDropdownItem,BSkeleton, BCard,BSkeletonTable, BLink 
        } from 'bootstrap-vue'
        import "jquery/dist/jquery.min.js";

        import $ from "jquery";
        import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
        import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
        
        export default {
          components: {
            BButton,
            BForm,
            BLink,
            BSkeleton,
            BSkeletonTable,
            BRow,
            BCol,
            BCard,
            BDropdown,BDropdownItem,
          },
          directives: {
          'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
          props: ['userId','tokenAuth', 'idAdmin'],
          data() {
        
            const mq = window.matchMedia( "(min-width: 990px)" );
        let columnas=6;
    
        if (!mq.matches) {
    
          columnas =3;
          }
    
        
            return {
              code200: false,
              loading: true,
               empty:false,
               columnas:columnas,
               productos:[],
               notAllow:false,
               description:"",

        
        
            }
        
        
          },
          computed: {
        
          },
          watch: {
        
          },
          mounted() {
      
            this.loadTable();
    
    
        
        
        
        
          },
          methods: {

            showModal(descripcion){
                this.description=descripcion;
        this.$refs.modalDescripcionSuscripcion.show();
    },

         

            aprobar(id, type){

          
            

                this.$swal({
                    title: "¿Seguro que deseas aprobar este producto?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: "Aprobar",
                    cancelButtonText: 'Cancelar',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                    const data_json = { userId: this.userId, id:id,idAdmin:this.idAdmin,type:type }
                    const data_string = JSON.stringify(data_json)

                

                    const dataEncripted = this.$encryptAdmin.encrypt(data_string)

                    return this.$https.post('/administracion/aprobarRechazarProducto/', {  tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                        this.$swal.showValidationMessage(
                        error,
                        )
                    })
                    },
                    allowOutsideClick: () => !this.$swal.isLoading(),
                }).then(result => {

                    this.$swal.close()

                    if(result.isConfirmed){
                     
                    if (result.value.code == 200) {

                       this.refresh();

                        
                                this.$toast.success(result.value.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                                })


                
                            } else {
                               
                              this.refresh();

                                this.$toast.error(result.value.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                                })

                                if (result.data.code == 401) {
                                
                                localStorage.removeItem('userData')

                                

                                
                                

                    

                                
                                

                                // Redirect to login page
                                this.$router.push({ name: 'auth-login' })
                                }
                            }

                    }
                    



            
                })
  
        
    
            },
        
            refresh() {
              
              this.$eventBus.$emit('reiniciarCountPublicacionesAdmin')
              this.$eventBus.$emit('reiniciarMarketplaceAdmin')
            },rechazar(id, type){
    

            
                this.$swal({
                title: "¿Seguro que deseas rechazar este producto?",
                input: 'text',
                inputPlaceholder: "Motivo del rechazo de este producto",
                inputAttributes: {
                    autocapitalize: 'off'
                },
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: "Rechazar",
                cancelButtonText: 'Cancelar',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: (motivo) => {

                    if (motivo != "") {

                        const data_json = { userId: this.userId,type:type, id:id,idAdmin:this.idAdmin }
                            const data_string = JSON.stringify(data_json)
                
                        
                
                            const dataEncripted = this.$encryptAdmin.encrypt(data_string)
                
                            return this.$https.post('/administracion/aprobarRechazarProducto/', {motivo:motivo, tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                                this.$swal.showValidationMessage(
                                error,
                                )
                            })


                    } else {
                        this.$swal.showValidationMessage('El motivo del rechazo es obligatorio')   
                   }
               
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
              }).then(result => {
    
                this.$swal.close()
    
                if(result.isConfirmed){
    
       
    
                 
                  if (result.value.code == 200) {
    
                    this.refresh();
    
                        
                       
                            this.$toast.success(result.value.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })
    
    
            
                          } else {

                            this.refresh();
    
                            this.$toast.error(result.value.message, {
                              position: 'top-right',
                              timeout: 3010,
                              closeOnClick: true,
                              pauseOnFocusLoss: false,
                              pauseOnHover: true,
                              draggable: true,
                              draggablePercent: 0.4,
                              showCloseButtonOnHover: false,
                              hideProgressBar: true,
                              closeButton: 'button',
                              icon: true,
                              rtl: false,
                            })
    
                            if (result.data.code == 401) {
                              
                              localStorage.removeItem('userData')


                              
    
                              // Redirect to login page
                              this.$router.push({ name: 'auth-login' })
                            }
                          }
    
                }
                
    
    
    
          
              })
    
           
    
    
            },eliminar(id, type){
    

                        
                this.$swal({
                title: "¿Seguro que deseas eliminar este producto?",
                input: 'text',
                inputPlaceholder: "Motivo de eliminación de este producto",
                inputAttributes: {
                    autocapitalize: 'off'
                },
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                cancelButtonText: 'Cancelar',
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: (motivo) => {

                    if (motivo != "") {

                        const data_json = { userId: this.userId,type:type, id:id,idAdmin:this.idAdmin }
                            const data_string = JSON.stringify(data_json)
                
                        
                
                            const dataEncripted = this.$encryptAdmin.encrypt(data_string)
                
                            return this.$https.post('/administracion/aprobarRechazarProducto/', {motivo:motivo, tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => response.data).catch(error => {
                                this.$swal.showValidationMessage(
                                error,
                                )
                            })


                    } else {
                        this.$swal.showValidationMessage('El motivo del rechazo es obligatorio')   
                }
            
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then(result => {

                this.$swal.close()

                if(result.isConfirmed){



                
                if (result.value.code == 200) {

                  this.refresh();

                        
                    
                            this.$toast.success(result.value.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            })



                        } else {

                          this.refresh();

                            this.$toast.error(result.value.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                            })

                            if (result.data.code == 401) {
                            
                            localStorage.removeItem('userData')


                            

                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            }
                        }

                }
                




            })




            },
          loadTable(){
              const data_json = { userId: this.userId, idAdmin: this.idAdmin }
              const data_string = JSON.stringify(data_json)
    
        
              const dataEncripted = this.$encryptAdmin.encrypt(data_string);
    
                this.$https.post('/administracion/productosRevision/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
    
                  this.loading=false;

                    
                  if (response.data.code == 200) {
                    this.productos= response.data["productos"];
                
                                
                        setTimeout(() => {
                          $("#datatableProductos").DataTable({
                            responsive: true,
                            "ordering": false,
                            columnDefs: [
                                                    { responsivePriority: 1, targets: 0 },
                                                    { responsivePriority: 2, targets: -1 }
                                                ],
                            lengthMenu: [
                              [5,15,25, 35, 50, -1],
                              [5,15,25, 35, 50, "All"],
                            ],
                            pageLength: 5, 
                               "language": {
                                                  "search": "Buscar:",
                                                  "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                  "searchPlaceholder": "Ingrese algún dato",
                                                  "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                  "processing": "Buscando. Espere por favor...",
                                                  "sLengthMenu":     "Mostrar _MENU_ registros",
                                                  "oPaginate": {
                                                          "sFirst":    "Primero",
                                                          "sLast":     "Último",
                                                          "sNext":     "Siguiente",
                                                          "sPrevious": "Anterior"
                                                      },
    
                                                  }
                          });
                        });
    
                            this.code200=true;
    
                  
                    
    
    
                    
                  } else {
                    
                        
    
    
                    if (response.data.code == 401) {
    
                        this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })
    
                        
                        localStorage.removeItem('userData')


    
                        
    
    
    
    
                        
                        
    
                        // Redirect to login page
                        this.$router.push({ name: 'auth-login' })
                        } else {
    
                          if (response.data.code == 403) {
    
                            this.$router.push({ name: 'dashboard-analytics' })
    
                            }else{

                              if(response.data.code === 501){

                              this.notAllow=true;

                              }else{
                                if (response.data.code == 404) {
                                    
                                    
                                    
                                    this.empty=true;

                                    }else{

                                      this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                      })

                                      this.code200 = false

                                    }

                                }
                           
    
                            }
    
                  
    
    
    
                        } 
                      
                    
                  }
                }).catch(error => {
                    this.loadTable();
                })
            
            }
        
        
          },
        }
        </script>
        
        <style lang="scss">
        
        
        </style>