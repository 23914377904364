<template>

    <b-card>

        <b-form
    autocomplete="off"
    @submit.prevent="onSubmit"
  >

      <b-row>

       
        <b-col  cols="12" sm="12" md="12" lg="12" xl="12">

            <p style="text-align: center; font-weight: bold; font-size: 14px;">Mensajería masiva</p>

            </b-col>

      <b-col  cols="12" sm="12" md="12" lg="12" xl="12">
        <p style="text-align: left; font-weight: bold; font-size: 12px;">Seleccione el archivo .csv:</p>

        <b-form-file
                          id="datos"
                          v-model="datos"
                          required
                          :disabled="isDisabled"
                          accept=".csv"
                          placeholder="Seleccione una archivo excel o suéltelo aquí..."
                          drop-placeholder="Suelte el archivo aqui..."
                          @change="onChange"
                        />

   
   
      </b-col>

      <b-col  cols="12" sm="12" md="12" lg="12" xl="12"  style="margin-top:15px">
        <p style="text-align: left; font-weight: bold; font-size: 12px;">Ingrese el From de Twilio:</p>

        <b-form-input
        v-model="from"
        placeholder="Ingrese el from de Twilio"
        required
        :readonly="isDisabled"
        type="text"
    />
      </b-col>

      <b-col  cols="12" sm="12" md="12" lg="12" xl="12"  style="margin-top:15px">
        <p style="text-align: left; font-weight: bold; font-size: 12px;">Ingrese el contentSid de Twilio:</p>

        <b-form-input
        v-model="contentSid"
        placeholder="Ingrese el contentSid de Twilio"
        required
        :readonly="isDisabled"
        type="text"
    />
      </b-col>


      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="margin-top:15px"
      >

        <b-button
          id="btn_enviar"
          ref="btn_enviar"
          type="submit"
          variant="primary"
          :disabled="isDisabled"
          block
        >

          Enviar mensajes masivos

        </b-button>

      </b-col>

    
    </b-row>
 
  </b-form>
        </b-card>
      
         
      
</template>
      
      <script>
      import {
        BButton, BSkeleton, BRow, BCol, BCard,BFormInput,BFormFile, BForm
      } from 'bootstrap-vue'

      
      export default {
        components: {
            BForm,
            BFormInput,BFormFile,
            BSkeleton,
          BButton,
          BRow,
          BCol,
          BCard,
        },
        props: ['idAdmin','tokenAuth','userId'],
        data() {
      
      
          return {
            isDisabled:false,
            datos: [],
            datosCSV:[],
            from:"",
            contentSid:"",
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

      

      
        },
        methods: {

            onSubmit(event) {
                  event.preventDefault();

                  this.isDisabled=true;
          
                  this.$swal({
                        
                        html:'<img style="margin-left:15px;width:20px; " src="/img/loading.svg" alt="Loading" /> Enviando mensajes, espere',
                        showCloseButton: false,
                        showCancelButton: false,
                        focusConfirm: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        position: 'center',
                        
                        })

                        console.log(JSON.stringify({tokenAuth: this.tokenAuth, datos: this.datosCSV, from: this.from, contentSid: this.contentSid}));
                    
                        this.$https.post('/chat/enviosMasivos/', { tokenAuth: this.tokenAuth, datos: this.datosCSV, from: this.from, contentSid: this.contentSid }).then(response => {
                            this.$swal.close();
                            this.datosCSV=[];

                            this.datos=null;
  
                        this.isDisabled=false;
                        this.from="";
                          this.contentSid="";
                    
                        if (response.data.code == 200) {
                
            
                
                        this.$toast.success(response.data.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                        });
                
                        
                
                        } else {
                
                        this.$toast.error(response.data.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                        })
                
                        if (response.data.code == 401) {
                
                        
                
                        localStorage.removeItem('userData')
                
                
                        this.$router.push({ name: 'auth-login' })
                        }
                        }
                        }).catch(error => {
                        this.$toast.error("Ocurrio un error " + error, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                        })
                        })

                  

            },

            onChange(event) {
                var vm = this;
                // Get the selected file from the input element
                    const selectedFile = event.target.files[0];
                    
                    // Initialize a FileReader object
                    const reader = new FileReader();
                    
                    // Define what happens when the file has been read
                    reader.onload = function(event) {
                        const fileContent = event.target.result;
                        
                        // Convert CSV to array
                        const rows = fileContent.split(/\r\n|\n|\r/);
                        vm.datosCSV = rows.map(row => row.split(';'));
                    
                        // Log the array to the console
                        console.log(vm.datosCSV);
                    };

                    // Handle errors
                    reader.onerror = function(error) {

                        vm.datos=null;
                        vm.datosCSV=[];
                        this.$toast.error(error, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                        })
                    };
                    
                    // Read the file as text
                    reader.readAsText(selectedFile);
                   
                    },

      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      