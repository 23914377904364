<template>

    <b-row v-if="loading" style="margin-bottom:15px"> 

                <b-col  cols="4" sm="4" md="4" xl="4" lg="4">


                </b-col>

                <b-col  cols="4" sm="4" md="4" xl="4" lg="4">

                <b-skeleton width="100%"  height="30px"></b-skeleton>

                </b-col>

                <b-col  cols="4" sm="4" md="4" xl="4" lg="4">


                </b-col>

                <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


                    </b-col>

                    <b-col  cols="6" sm="6" md="6" xl="6" lg="6">

                    <b-skeleton width="100%"  height="30px"></b-skeleton>

                    </b-col>

                    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


                    </b-col>

                <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">


                    <b-skeleton-table
                :rows="2"
                :columns="columnas"
                :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>

                </b-col>

                <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


                </b-col>

                <b-col  cols="6" sm="6" md="6" xl="6" lg="6">

                <b-skeleton width="100%"  height="30px"></b-skeleton>

                </b-col>

                <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


                </b-col>

                <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


                </b-col>

                <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
                <b-skeleton width="100%"  height="30px"></b-skeleton>


                </b-col>

                <b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
                <b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>


                </b-col>

                <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
                <b-skeleton width="100%"  height="30px"></b-skeleton>


                </b-col>


                <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


                </b-col>
      
    
     </b-row>
    
     <b-row v-else-if="code200">
      
     


    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto;padding: 0px;margin-top:15px">

        
    
      <table class="table tablesorter"  id="datatable2" >
                                  <thead class="thead-light">
                                    <tr>
                                      <th>Fecha/Hora</th>
                                      <th>Motivo</th>
              
                       
                                 
                                     
                                    </tr>
                                  </thead>
                                  <tbody>
    
    
                                    <tr v-for="transaccion in transactions" :key="transaccion.id">
    
                                     <td> {{ transaccion.date }}</td>
    
                                     <td>{{ transaccion.motivo }}</td>
                 

    
                                    </tr>
                                  </tbody>
                                </table>
                        
    
     </b-col>
    
    
     
    
     </b-row>
    
     <b-row v-else-if="empty">
    
 
    
     <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">
      <svg class="icons_colors" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 455 455"><g><circle cx="69.803" cy="299.013" r="34.902"/><path d="M69.803 363.914C31.252 363.914.0 395.167.0 433.718h139.606c0-38.551-31.252-69.804-69.803-69.804z"/><circle cx="385.197" cy="299.013" r="34.902"/><path d="M385.197 363.914c-38.551.0-69.803 31.252-69.803 69.803H455c0-38.55-31.252-69.803-69.803-69.803z"/><circle cx="227.5" cy="56.184" r="34.901"/><path d="M227.5 121.085c-38.551.0-69.803 31.252-69.803 69.803h139.606c0-38.551-31.252-69.803-69.803-69.803z"/><polygon points="242.5,240.773 212.5,240.773 212.5,308.896 153.504,342.958 168.504,368.939 227.5,334.877 286.496,368.939 301.496,342.958 242.5,308.896"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
  

                             <p class="errorTexto">
                            No se encontraron desbloqueos
                          </p>
    
                  </b-col>
    
     <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="    text-align: center;">
    
                               <b-button   variant="primary"  @click="refresh()" > 
                                             <feather-icon
                                              icon="RefreshCcwIcon"
                                              size="16"
                                             
                                              style="margin-right:5px; color:#fff"
                                              /> Recargar listado </b-button>
    
    
     </b-col>
    </b-row>

    <b-row v-else-if="notAllow">
    
 
    
    <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">
 
        <img src="/img/error.svg" style="width: 100px;"/>
                            <p class="errorTexto" style="margin-top:10px">
                          No tienes permiso para ver este listado
                         </p>
   
                 </b-col>
   

   </b-row>

    </template>
    
    <script>
    import {
      BButton, BRow, BCol, BCard,BSkeletonTable, BSkeleton
    } from 'bootstrap-vue'
    import "jquery/dist/jquery.min.js";
    
    import $ from "jquery";
    import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
    import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
    
    export default {
      components: {
        BButton,
        BSkeleton,
        BSkeletonTable,
        BRow,
        BCol,
        BCard,
      },
      props: ['userId','tokenAuth', 'idAdmin','userIdUser'],
      data() {
    
 
    
    
        return {
          code200: false,
          loading: true,
           empty:false,
           notAllow:false,
           columnas:2,
           transactions:[],
    
        }
      },
      computed: {
    
      },
      watch: {
    
      },
      mounted() {

            

    
        this.loadTable();
    
    
    
    
    
      },
      methods: {
    
        refresh() {
          this.$eventBus.$emit('reiniciarListadoMotivosDesbloqueos')
        }, loadTable(){

            const data_json = { userId: this.userId, idAdmin: this.idAdmin, userIdUser: this.userIdUser }
          
            const data_string = JSON.stringify(data_json)


            const dataEncripted = this.$encryptAdmin.encrypt(data_string);

    
         this.$https.post('/administracion/searchMotivosDesban/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
             this.loading=false;

     
           
                          if(response.data["code"] == 200){
                          
                         
                    
                           
    
                            this.transactions= response.data["transactions"];
     
                     
                                      setTimeout(() => {
                                        $("#datatable2").DataTable({
                                          responsive: true,
                                          "ordering": false,
                                          lengthMenu: [
                                            [5,10, 25, 50, -1],
                                            [5,10, 25, 50, "All"],
                                          ],
                                          pageLength: 5, 
                                             "language": {
                                                  "search": "Buscar:",
                                                  "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                  "searchPlaceholder": "Ingrese algún dato",
                                                  "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                  "processing": "Buscando. Espere por favor...",
                                                  "sLengthMenu":     "Mostrar _MENU_ registros",
                                                  "oPaginate": {
                                                          "sFirst":    "Primero",
                                                          "sLast":     "Último",
                                                          "sNext":     "Siguiente",
                                                          "sPrevious": "Anterior"
                                                      },
    
                                                  }
                                        });
                                      });
    
                                         this.code200=true;
    
                         
      
                              
                            }else{
    
               
                                
    
    
                                      if (response.data.code == 401) {
    
                                        this.$toast.error(response.data.message, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                            })
    
    
                                        
                                        localStorage.removeItem('userData')


    
                                        // Redirect to login page
                                        this.$router.push({ name: 'auth-login' })
                                      } else {
    
                                         if (response.data.code == 404) {
    
    
    
                                          this.empty=true;
    
                                        }else{

                                            if (response.data.code == 405) {
    
    
                                                                
                                                this.notAllow=true;

                                            }else{



    
                                             this.$toast.error(response.data.message, {
                                              position: 'top-right',
                                              timeout: 3010,
                                              closeOnClick: true,
                                              pauseOnFocusLoss: false,
                                              pauseOnHover: true,
                                              draggable: true,
                                              draggablePercent: 0.4,
                                              showCloseButtonOnHover: false,
                                              hideProgressBar: true,
                                              closeButton: 'button',
                                              icon: true,
                                              rtl: false,
                                            })
    
                                            this.code200 = false

                                        }
    
                                        }
                                      
    
    
                                      }
    
                            }
                     }).catch((error) => {
    
                              this.loadTable();
    
                         
                     });
        }
    
    
      },
    }
    </script>
    
    <style lang="scss">
    
    
    </style>